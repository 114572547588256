import {connect} from "react-redux";
import React, {useCallback, useEffect, useRef, useState} from "react";
import InputTextGroup from "../../../../components/common/InputTextGroup";
import InputSelectGroup from "../../../../components/common/InputSelectGroup";
import InputTextArea from "../../../../components/common/InputTextArea";
import InputTextSearch from "../../../../components/common/InputTextSearch";
import {useSearchParams} from "react-router-dom";
import {CModal, CModalBody, CModalHeader} from "@coreui/react";
import StoreSearch from "../../board/storeSearch";
import * as storeMethod from "../../../../api/method/store";
import {AgGridReact} from "ag-grid-react";
import InputDate from "../../../../components/common/InputDate";
import * as api from "../../../../api/api";
import dayjs from "dayjs";
import {Loading} from "../../../../components/common";
import ComboBox from "../../../../components/common/ComboBox";
import StorageRenderer from "../components/StorageRenderer";
import ProductTypeRenderer from "../components/ProductTypeRenderer";
import ProductClassRenderer from "../components/ProductClassRenderer";
import {DatePicker} from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import axios from "axios";
import SupplierSearch from "../SupplierSearch";

const StockIn = (props) => {
    const [params] = useSearchParams();
    const mode = params.get("mode");
    const companyCode = params.get("companyCode");
    const accountId = params.get("accountId");

    const [stockIn, setStockIn] = useState({});
    const [stockInSeq, setStockInSeq] = useState(params.get("stockInSeq"));
    const [loading, setLoading] = useState(false);
    const [searchForm, setSearchForm] = useState({
        seq: -1, companyCode: companyCode,
        storeKey: "", storeName: "", productNm: "", stockDt: dayjs(), memo: "", reason: "0"
    });
    const [storageList, setStorageList] = useState([]);
    const [productTypeList, setProductTypeList] = useState([]);
    const [stockList, setStockList] = useState([]);
    const [selectedStock, setSelectedStock] = useState(-1);
    const [selectedStockData, setSelectedStockData] = useState({
        serialList: []
    });
    const [serialNo, setSerialNo] = useState("");
    const [serialList, setSerialList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [storeSearchModal, setStoreSearchModal] = useState({
        open: false, data: []
    });
    const defaultClassType = {seq: "", classNm: "없음"};
    const [itemLClassList, setItemLClassList] = useState([defaultClassType]);
    const [itemMClassList, setItemMClassList] = useState([defaultClassType]);
    const [filterMClassList, setFilterMClassList] = useState([defaultClassType]);
    const [itemSClassList, setItemSClassList] = useState([defaultClassType]);
    const [filterSClassList, setFilterSClassList] = useState([defaultClassType]);

    const stockGridRef = useRef();
    const [stockColumnDefs, setStockColumnDefs] = useState([
        {field: 'seq', headerName: 'No', maxWidth: 55, cellClass: 'normal-cell'},
        {field: 'storageCd', headerName: '창고명', cellRenderer: StorageRenderer,
            cellRendererParams: {
                onEventChange: (rowIndex, value) => {
                    const data = [];
                    const {api} = stockGridRef.current;
                    api.forEachNode(function (rowNode, index) {
                        data.push(rowNode.data);
                    });

                    data[rowIndex].storageCd = value;
                    setStockList(data);
                }
            }, cellClass: 'normal-cell'
        },
        {field: 'itemLclassCd', headerName: '대분류', hide: true},
        {field: 'itemLclassNm', headerName: '대분류'},
        {field: 'itemMclassCd', headerName: '중분류', hide: true},
        {field: 'itemMclassNm', headerName: '중분류'},
        {field: 'itemSclassCd', headerName: '소분류', hide: true},
        {field: 'itemSclassNm', headerName: '소분류'},
        {field: 'productNm', headerName: '품목명'},
        {field: 'vatYn', headerName: '부가세', maxWidth: 80, cellRenderer: ComboBox,
            cellRendererParams: {
                options: [
                    {subCode: "0", codeNm: "미포함"},
                    {subCode: "1", codeNm: "포함"},
                ],
                onEventChange: (rowIndex, value) => {
                    const data = [];
                    const {api} = stockGridRef.current;
                    api.forEachNode(function (rowNode, index) {
                        data.push(rowNode.data);
                    });

                    data[rowIndex].vatYn = value;
                    setStockList(data);
                }
            }
        },
        {field: 'price', headerName: '입고단가', maxWidth: 85, editable: true},
        {field: 'qty', headerName: '수량', maxWidth: 60, editable: true},
        {field: 'grade', headerName: '등급', hide: true, maxWidth: 70, cellRenderer: ComboBox,
            cellRendererParams: {
                options: [
                    {subCode: "New", codeNm: "신규"},
                    {subCode: "A", codeNm: "중고-A"},
                    {subCode: "B", codeNm: "중고-B"},
                    {subCode: "C", codeNm: "중고-C"},
                    {subCode: "D", codeNm: "중고-D"},
                ],
                onEventChange: (rowIndex, value) => {
                    const data = [];
                    const {api} = stockGridRef.current;
                    api.forEachNode(function (rowNode, index) {
                        data.push(rowNode.data);
                    });

                    data[rowIndex].grade = value;
                    setStockList(data);
                }
            }
        },
        {field: "delete", headerName: "삭제", maxWidth: 60, cellRenderer: e => {
                return <button className={"text-black text-lg px-1 font-bold"} onClick={() => deleteRow(e)}>X</button>
            }
        }
    ])

    const defaultColDef = {
        flex: 1,
        resizable: true,
        sortable: true,
        filter: false,
        autoHeaderHeight: true
    }

    const serialGridRef = useRef();
    const [serialColumnDefs] = useState([
        {field: 'seq', headerName: 'No', maxWidth: 60},
        {field: 'lotNo', headerName: '제품번호'},
        {field: "delete", headerName: "삭제", maxWidth: 60, cellRenderer: e => {
                return <button className={"text-black text-lg px-1 font-bold"} onClick={() => deleteSerialRow(e)}>X</button>
            }
        }
    ])

    const productGridRef = useRef();
    const [productColumnDefs] = useState([
        {field: 'seq', headerName: 'No', maxWidth: 60},
        {field: 'productType', headerName: '품목분류', hide: true},
        {field: 'productTypeNm', headerName: '품목분류', hide: true},
        {field: 'itemLclassNm', headerName: '대분류'},
        {field: 'itemMclassNm', headerName: '중분류'},
        {field: 'itemSclassNm', headerName: '소분류'},
        {field: 'productCd', headerName: '품목코드', hide: true},
        {field: 'productNm', headerName: '품목명', width: 120},
        {field: 'price', headerName: '단가'},
        {field: 'stockQty', headerName: '재고수량', valueGetter: (e) => {
                return e.data.stockInQty - e.data.stockOutQty
            }},
        {field: 'memo', headerName: '메모'},
        {
            field: 'useProductNo', headerName: '제품번호', valueFormatter: (e) => {
                return e.value === 'Y' ? "사용" : '제외'
            }
        },
    ])

    useEffect(() => {
        getStorageList();
        getStockProductType();

        if (stockInSeq.length > 0) {
            search(stockInSeq);
        }
    }, [])

    const getStorageList = () => {
        setLoading(true);
        api.getStorageList(searchForm).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                const optionArr = [];
                data.map(item => {
                    const option = {
                        subCode: item.storageCd,
                        codeNm: item.storageNm
                    }
                    optionArr.push(option);
                })
                console.log("### storage :: ", optionArr);
                setStorageList(optionArr);
            }
            else {
                window.alert(statusText);
            }
        })
            .catch(error => {
                window.alert(error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const getStockProductType = () => {
        setLoading(true);
        const params = {
            companyCode: 'VAN',
            categoryDiv: 'P'
        }
        api.getCategories(params)
            .then(result => {
                const {status, statusText, data} = result;
                if (status === 200) {
                    // 대분류
                    const lClassList = data.filter(item => item.classDiv === 'L' && item.parentCd === null);
                    setItemLClassList([defaultClassType]);
                    setItemLClassList((state) => state.concat(lClassList));

                    const mClassList = data.filter(item => item.classDiv === 'M');
                    setItemMClassList([defaultClassType]);
                    setItemMClassList((state) => state.concat(mClassList));

                    const sClassList = data.filter(item => item.classDiv === 'S');
                    setItemSClassList([defaultClassType]);
                    setItemSClassList((state) => state.concat(sClassList));
                }
                else {
                    console.error(statusText);
                }
            })
            .catch((e) => {
                console.error(e.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const search = (seq) => {
        setLoading(true);
        api.getStockIn(seq).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                setSearchForm({
                    ...searchForm,
                    seq: data.seq,
                    storeKey: data.storeKey,
                    storeName: data.storeName,
                    stockDt: dayjs(data.stockDt), memo: data.memo, reason: data.reason
                })
                setStockIn(data);
                setStockList(data.stockInDetailList);
            }
            else {
                window.alert(statusText);
            }
        })
            .catch(error => {
                window.alert(error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const handleWhere = (e) => {
        const {name, value} = e.target;
        if (name === "itemLclassCd") {
            setSearchForm({
                ...searchForm,
                itemLclassCd: value,
                itemMclassCd: "",
                itemSclassCd: ""
            })
            setFilterMClassList([defaultClassType]);
            setFilterSClassList([defaultClassType]);

            // 중분류 필터
            const filterList = itemMClassList.filter(item => item.parentCd === value);
            setFilterMClassList((state) => state.concat(filterList));
        }
        else if (name === "itemMclassCd") {
            setSearchForm({
                ...searchForm,
                itemMclassCd: value,
                itemSclassCd: ""
            })
            // 소분류 필터
            const filterList = itemSClassList.filter(item => item.parentCd === value);
            setFilterSClassList([defaultClassType]);
            setFilterSClassList((state) => state.concat(filterList));
        }
        else {
            setSearchForm({
                ...searchForm,
                [name]: value
            })
        }
    }

    const handleSerialNo = (e) => {
        const {name, value} = e.target;
        setSerialNo(value);
    }

    const getStockInPlace = () => {
        const value = searchForm.storeName;
        // 입고처 정보 가져오기
        const params1 = {
            companyCode: companyCode,
            storeSyn: value
        }

        const params2 = {
            companyCode: companyCode,
            supplierNm: value,
            useYn: "Y"
        }

        setLoading(true);
        axios.all([
            api.getStoreWhere(params1),
            api.getSupplierList(params2)
        ]).then(axios.spread((response1, response2) => {
            // 데이터 포맷팅
            const formattedData1 = response1.data.map(item => ({
                seq: item.seq + "",
                supplierCd: item.storeKey + "",
                type: "가맹점",
                supplierNm: item.storeName
            }))

            const formattedData2 = response2.data.map(item => ({
                seq: item.seq + "",
                supplierCd: item.supplierCd,
                type: "공급사",
                supplierNm: item.supplierNm
            }))

            const combineData = [...formattedData1, ...formattedData2];
            setStoreSearchModal({
                open: true,
                data: combineData
            })
        }))
            .catch(ex => {
                console.error("### error :: ", ex.message())
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const getSupplierList = () => {
        const value = searchForm.storeName;
        const params = {
            companyCode: companyCode,
            useYn: "Y"
        }

        setLoading(true);
        api.getSupplierList(params).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                setStoreSearchModal({
                    open: true,
                    data: data
                })
            }
            else {
                window.alert(statusText);
            }
        })
            .catch(error => {
                window.alert(error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const storeSelectCallback = (e) => {
        setStoreSearchModal({
            open: false, data: []
        })

        setSearchForm((state) => ({
            ...state,
            storeKey: e.supplierCd,
            storeName: e.supplierNm
        }))
    }

    const getProductList = () => {
        const params = {
            companyCode: companyCode,
            itemLclassCd: searchForm.itemLclassCd,
            itemMclassCd: searchForm.itemMclassCd,
            itemSclassCd: searchForm.itemSclassCd,
            productNm: searchForm.productNm
        }

        setLoading(true);
        api.getStockProduct(params).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                setProductList(data)
            }
            else {
                window.alert(statusText);
            }
        })
            .catch(error => {
                window.alert(error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const addRow = () => {
        const stock = {
            seq: "",
            companyCode: 'VAN',
            storageCd: '0',
            storageNm: "",
            productType: '0',
            productCd: "",
            productNm: "",
            vatYn: '0',
            price: 0,
            qty: 1,
            grade: 'A',
            serialList: []
        }
        setStockList((state) => {
            return [...state, stock];
        })
    }

    const deleteRow = (e) => {
        const data = [];
        const {api} = stockGridRef.current;
        api.forEachNode(function (rowNode, index) {
            data.push(rowNode.data);
        });
        data.splice(e.rowIndex, 1)
        setStockList(data);
        setSelectedStock(-1);
    }

    const deleteSerialRow = (e) => {
        const data = [];
        const {api} = serialGridRef.current;
        api.forEachNode(function (rowNode, index) {
            data.push(rowNode.data);
        });
        data.splice(e.rowIndex, 1)
        setSerialList(data);
    }

    const onSave = () => {
        if (window.confirm("입고 정보를 저장하시겠습니까?")) {
            // 입고처 등록 여부
            const {seq, stockDt, storeKey, reason, memo} = searchForm;
            if (storeKey.length === 0) {
                window.alert("입고처를 먼저 선택해주세요.");
                return;
            }

            const stockIn = {
                seq: seq,
                companyCode: companyCode,
                stockDt: dayjs(stockDt).format("YYYYMMDD"),
                storeKey: storeKey,
                purchaseAmt: 0,
                vatAmt: 0,
                totalAmt: 0,
                reason: reason,
                memo: memo,
                stockInDetailList: [],
                instUser: accountId,
                updUser: accountId
            }

            let stockQty = 0;
            let vatAmt = 0;
            let purchaseAmt = 0;

            const data = [];
            stockGridRef.current.api.forEachNode(function (rowNode, index) {
                console.log("### rowNode :: ", rowNode.data);
                let vat = 0;
                const purchase = rowNode.data.price * rowNode.data.qty;
                const qty = rowNode.data.qty;
                if (rowNode.data.vatYn === "1") {
                    vat = Math.round(purchase - (purchase / 1.1));
                }
                purchaseAmt += purchase;
                vatAmt += vat;
                stockQty += qty;

                data.push(rowNode.data);
            });

            stockIn.stockInDetailList = data;
            stockIn.purchaseAmt = purchaseAmt;
            stockIn.vatAmt = vatAmt;
            stockIn.totalAmt = purchaseAmt + vatAmt;
            stockIn.stockQty = stockQty;

            putStockIn(stockIn);
        }
    }

    const putStockIn = (stockIn) => {
        setLoading(true);
        api.putStockIn(stockIn).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                window.alert("정상적으로 처리되었습니다.");
                setSearchForm({
                    seq: data.seq,
                    ...searchForm,
                })
                search(data.seq);
            }
            else {
                window.alert(statusText);
            }
        })
            .catch(error => {
                window.alert(error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const onRowSelected = (e) => {
        if (!e.node.selected) return;
        setSelectedStock(e.node.rowIndex);
        setSelectedStockData(e.node.data);

        setSerialList(e.node.data.serialList);
    }

    const onRowDoubleClicked = (e) => {
        let stock = {};
        console.log("### selected :: ", selectedStock);
        if (selectedStock > -1) {
            // 선택한 재고상품 업데이트
            const copiedItems = [...stockList];
            copiedItems[selectedStock].itemLclassCd = e.data.itemLclassCd;
            copiedItems[selectedStock].itemLclassNm = e.data.itemLclassNm;
            copiedItems[selectedStock].itemMclassCd = e.data.itemMclassCd;
            copiedItems[selectedStock].itemMclassNm = e.data.itemMclassNm;
            copiedItems[selectedStock].itemSclassCd = e.data.itemSclassCd;
            copiedItems[selectedStock].itemSclassNm = e.data.itemSclassNm;
            copiedItems[selectedStock].productType = e.data.productType;
            copiedItems[selectedStock].productCd = e.data.productCd;
            copiedItems[selectedStock].productNm = e.data.productNm;
            copiedItems[selectedStock].price = e.data.price;
            setStockList(copiedItems);
        }
        else {
            stock.seq = "";
            stock.companyCode = 'VAN';
            stock.storageCd = storageList[0].subCode;
            stock.itemLclassCd = e.data.itemLclassCd;
            stock.itemLclassNm = e.data.itemLclassNm;
            stock.itemMclassCd = e.data.itemMclassCd;
            stock.itemMclassNm = e.data.itemMclassNm;
            stock.itemSclassCd = e.data.itemSclassCd;
            stock.itemSclassNm = e.data.itemSclassNm;
            stock.productType = e.data.productType;
            stock.productCd = e.data.productCd;
            stock.productNm = e.data.productNm;
            stock.vatYn = '0';
            stock.price = e.data.price;
            stock.qty = 1;
            stock.serialList = [];

            setStockList((state) => {
                return [...state, stock];
            })
        }
        setSelectedStock(-1);
    }

    const addSerial = () => {
        if (selectedStock < 0) {
            window.alert("입고 대상을 선택하세요.");
            return;
        }

        if (serialNo.length === 0) {
            window.alert("제품번호를 입력하세요.");
            return;
        }

        const idx = serialList.findIndex(item => item.lotNo === serialNo);
        if (idx > -1) {
            window.alert("이미 등록되어 있는 제품번호입니다.")
            return;
        }

        const serial = {
            seq: "",
            lotNo: serialNo,
        }

        const copiedItems = [...stockList];
        let tempSerialList = copiedItems[selectedStock].serialList;
        tempSerialList = [...serialList, serial];
        console.log("temp :: ", tempSerialList);

        copiedItems[selectedStock].serialList = tempSerialList;
        setStockList(copiedItems);

        setSerialList((state) => {
            return [...state, serial];
        })

        setSerialNo("");
    }

    const onKeyPress = (e) => {
        if (e.key === "Enter") {
            addSerial();
        }
    }

    return (
        <div className={"flex flex-row gap-2 m-2"}>
            {loading ? <Loading/> : null}
            <div className={"flex flex-col bg-white border border-gray-800 p-2 shadow"}>
                <div className={"flex flex-col flex-1 border bg-gray-100"}>
                    <div className={"flex flex-row gap-1"}>
                        <div className={"flex flex-row p-2 gap-2 items-center"}>
                            <span className={"w-20 text-sm font-bold text-nowrap"}>입고일자</span>
                            <DatePicker className={"h-6 px-2 text-sm"}
                                        placeholder={""}
                                        locale={locale}
                                        value={searchForm.stockDt}
                                        onChange={(dayjs, dateString) => {
                                            setSearchForm((state) => ({
                                                ...state, stockDt: dayjs
                                            }));
                                        }}/>
                        </div>
                        <InputTextSearch name={"storeName"} title={"공급처"} value={searchForm.storeName}
                                         disabled={mode === "R"}
                                         search={getStockInPlace}
                                         setValue={handleWhere} />
                        <InputSelectGroup name={"reason"} title={"입고사유"} options={[
                            {subCode: "0", codeNm: "본사제품 입고"},
                            {subCode: "1", codeNm: "기기변경"},
                            {subCode: "2", codeNm: "변심"},
                            {subCode: "3", codeNm: "폐업"},
                            {subCode: "4", codeNm: "매입"},
                            {subCode: "5", codeNm: "타사변경"},
                        ]} setValue={handleWhere} value={searchForm.reason} />
                    </div>
                    <InputTextArea name={"memo"} title={"메모"} value={searchForm.memo} setValue={handleWhere} />
                </div>
                <div className="flex justify-content-between mt-1">
                    <div className={"flex flex-row gap-1"}>
                        {/*<button className={"bg-red-500 px-3 py-1 rounded text-white text-sm"} onClick={addRow}>추가</button>*/}
                    </div>
                    <div className={"d-grid gap-1 d-md-flex"}>
                        <button className={"bg-blue-950 px-3 py-1 rounded text-white text-sm"} onClick={onSave}>저장</button>
                    </div>
                </div>
                <div className="ag-theme-alpine mt-1" style={{width: '100%', height: 250}}>
                    <AgGridReact
                        ref={stockGridRef}
                        rowSelection={'single'}
                        rowHeight={32}
                        headerHeight={32}
                        rowData={stockList}
                        onRowSelected={onRowSelected}
                        defaultColDef={defaultColDef}
                        columnDefs={stockColumnDefs}>
                    </AgGridReact>
                </div>
                <div className={"mt-1"}>
                    <div className="flex justify-content-between">
                        <div className={"flex flex-row items-center gap-1"}>
                            <InputTextGroup title={"제품번호"}
                                            name={"lotNo"}
                                            onKeyPress={onKeyPress}
                                            value={serialNo}
                                            setValue={handleSerialNo} />
                            <button className={"bg-red-500 px-3 py-1 rounded text-white text-sm"} onClick={addSerial}>추가</button>
                        </div>
                        <div className={"d-grid gap-1 d-md-flex"}>
                            {/*<button className={"bg-blue-950 px-3 py-1 rounded text-white text-sm"} onClick={null}>순차증가</button>*/}
                        </div>
                    </div>
                    <div className="ag-theme-alpine mt-1" style={{width: '100%', height: 250}}>
                        <AgGridReact
                            ref={serialGridRef}
                            rowSelection={'single'}
                            rowHeight={32}
                            headerHeight={32}
                            rowData={serialList}
                            defaultColDef={defaultColDef}
                            columnDefs={serialColumnDefs}>
                        </AgGridReact>
                    </div>
                </div>
            </div>

            <div className={"flex flex-col flex-1 p-2 border border-gray-800 bg-white shadow"}>
                <div className={"mt-1"}>
                    <div className="flex justify-content-between">
                        <div className={"flex flex-col"}>
                            <div className={"flex flex-row"}>
                                <InputSelectGroup name={"itemLclassCd"}
                                                  title={"대분류"}
                                                  optionType={"C"}
                                                  value={searchForm.itemLclassCd}
                                                  options={itemLClassList}
                                                  setValue={handleWhere} />
                                <InputSelectGroup name={"itemMclassCd"}
                                                  title={"중분류"}
                                                  optionType={"C"}
                                                  value={searchForm.itemMclassCd}
                                                  options={filterMClassList}
                                                  setValue={handleWhere} />
                                <InputSelectGroup name={"itemSclassCd"}
                                                  title={"소분류"}
                                                  optionType={"C"}
                                                  value={searchForm.itemSclassCd}
                                                  options={filterSClassList}
                                                  setValue={handleWhere} />
                            </div>
                            <InputTextSearch name={"productNm"} title={"상품명"} search={getProductList} setValue={handleWhere} />
                        </div>
                    </div>
                    <div className="ag-theme-alpine mt-1" style={{width: '100%', height: 500}}>
                        <AgGridReact
                            ref={productGridRef}
                            rowSelection={'single'}
                            rowHeight={32}
                            headerHeight={32}
                            onRowDoubleClicked={onRowDoubleClicked}
                            rowData={productList}
                            defaultColDef={defaultColDef}
                            columnDefs={productColumnDefs}>
                        </AgGridReact>
                    </div>
                </div>
            </div>

            <CModal alignment="center"
                    size={"lg"}
                    visible={storeSearchModal.open}
                    onClose={() => setStoreSearchModal({
                        open: false, data: []
                    })}>
                <CModalBody>
                    <SupplierSearch data={storeSearchModal.data} callback={storeSelectCallback}/>
                </CModalBody>
            </CModal>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {account, store} = state;
    return {
        account, store
    };
};

export default connect(mapStateToProps, null)(StockIn);