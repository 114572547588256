import axios from 'axios'
// export const host = "http://localhost:8080/api/v1";
export const host = 'https://ziha-api.herokuapp.com/api/v1';

function post(request, body) {
    return axios.post(request, body);
}

function get(request, body) {
    return axios.get(request, {
        params: body
    });
}

function put(request, body) {
    return axios.put(request, body);
}

function patch(request, body) {
    return axios.patch(request, body);
}

export function account(accountId, password) {
    const request = host + '/account/' + accountId + '/' + password
    return get(request)
}

export function postAccount(account) {
    const request = host + '/account/';
    return post(request, account);
}

export function putAccount(account) {
    const request = host + '/account/';
    return put(request, account);
}
export function findAccountByCompanyCode(companyCode) {
    const request = host + '/account/' + companyCode;
    return get(request);
}

export function getStoreList(params) {
    const request = host + '/store/list/';
    return get(request, params);
}

export function getStore(storeKey) {
    const request = host + '/store/' + storeKey;
    return get(request);
}

export function getStoreWhere(body) {
    const request = host + '/store/list/where';
    return get(request, body);
}

export function postStore(body) {
    const request = host + '/store/';
    return post(request, body);
}

export function putStore(body) {
    const request = host + '/store/';
    return put(request, body);
}

export function postStoreList(body) {
    const request = host + "/store/list";
    return put(request, body);
}

export function getDashboard(accountId) {
    const request = host + '/board/dashboard/' + accountId;
    return get(request);
}

export function getBoardList(boardId) {
    const request = host + '/board/' + boardId;
    return get(request);
}

export function getBoardListWhere(params) {
    const request = host + '/board/list';
    return get(request, params);
}

export function postBoard(body) {
    const request = host + '/board/';
    return post(request, body);
}

export function putBoard(board) {
    const request = host + '/board/';
    return put(request, board);
}

export function patchBoard(board) {
    const request = host + '/board/';
    return patch(request, board);
}

export function getBoardDetail(seq) {
    const request = host + '/board/detail/' + seq;
    return get(request);
}

export function getBoardReply(boardId) {
    const request = host + '/board/detail/' + boardId + '/replies';
    return get(request);
}

export function getBoardTrace(seq) {
    const request = host + '/board/trace/' + seq;
    return get(request);
}

export function putBoardReply(body) {
    const request = host + '/board/reply';
    return put(request, body);
}

export function getAccountLocation(accountId) {
    const request = host + '/account/location/' + accountId;
    return get(request);
}

export function getWorkJournalList(body) {
    const request = host + '/work/list';
    return post(request, body);
}

export function putWorkJournal(body) {
    const request = host + '/work/journal';
    return put(request, body);
}

export function getWorkData(params) {
    const request = host + '/work/proc/my-list';
    return get(request, params);
}

export function getTeamInfo(params) {
    const request = host + '/account/team';
    return get(request, params);
}

export function putTeamInfo(params) {
    const request = host + '/account/team';
    return put(request, params);
}

export function selectWorkManagerList(params) {
    const request = host + '/work/manager/list';
    return get(request, params);
}

export function getCategories(params) {
    const request = host + '/board/category/all';
    return get(request, params);
}

export function getBoardCategories(params) {
    const request = host + '/board/category/list';
    return get(request, params);
}

export function getBoardCategory(seq) {
    const request = host + '/board/category/' + seq;
    return get(request);
}

export function putBoardCategory(boardCategory) {
    const request = host + '/board/category';
    return put(request, boardCategory);
}

export function getAccountBoardList(params) {
    const request = host + '/account/getAccountBoardList';
    return get(request, params);
}

export function getCommonCode(groupCode) {
    const request = host + '/common/detail/' + groupCode;
    return get(request);
}

export function putCommonCode(params) {
    const request = host + '/common/detail';
    return put(request, params);
}

export function getStockProduct(params) {
    const request = host + '/stock/product/list';
    return post(request, params);
}

export function putStockProduct(params) {
    const request = host + '/stock/product';
    return put(request, params);
}

export function getStorageList(params) {
    const request = host + "/stock/storage/list";
    return post(request, params);
}

export function putStorage(storage) {
    const request = host + "/stock/storage";
    return put(request, storage);
}

export function getCollectList(params) {
    const request = host + "/stock/collect/list";
    return post(request, params);
}

export function putCollect(collect) {
    const request = host + "/stock/collect";
    return put(request, collect);
}

export function getStockInList(params) {
    const request = host + "/stock/in/list";
    return post(request, params);
}

export function getStockOutList(params) {
    const request = host + "/stock/out/list";
    return post(request, params);
}

export function getStockIn(stockInSeq) {
    const request = host + "/stock/in/" + stockInSeq;
    return get(request);
}

export function getStockOut(stockOutSeq) {
    const request = host + "/stock/out/" + stockOutSeq;
    return get(request);
}

export function putStockIn(stockIn) {
    const request = host + "/stock/in";
    return put(request, stockIn);
}

export function putStockOut(stockOut) {
    const request = host + "/stock/out";
    return put(request, stockOut);
}

export function getProductSerialListByProductCd(product) {
    const request = host + "/stock/product/serial/list";
    return post(request, product);
}

export function getStoreHaveProductList(params) {
    const request = host + "/stock/out/store/list";
    return post(request, params);
}

export function getSupplierList(params) {
    const request = host + "/stock/supplier/list";
    return post(request, params);
}

export function putSupplier(supplier) {
    const request = host + "/stock/supplier";
    return put(request, supplier);
}

export function getProductSerialListAvailable(product) {
    const request = host + "/stock/available/serial/list";
    return post(request, product);
}

export function getOutSerialListByDetailSeq(detailSeq) {
    const request = host + "/stock/out/serial/" + detailSeq + "/list"
    return get(request)
}

export function getStorageStat() {
    const request = host + "/stock/storage/stat"
    return get(request)
}